
import { ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, FiatCurrency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	SourceOfDTO, MapFromDTO, Mapper, Control, FieldControlType, FieldViewType, Label, Required, View, titleCase
} from '@bp/shared/models/metadata';
import { isEmpty } from '@bp/shared/utilities/core';

import { externalLinkJsonPropertyDtoSourceMapper, PaymentOptionType } from '@bp/frontend/models/business';
import type { IRouteStaticPaymentOptionExternalLink } from '@bp/frontend/models/business';

import { RouteStaticPaymentOptionBase } from './route-static-payment-option-base';

export class RouteStaticPaymentOptionExternalLink extends RouteStaticPaymentOptionBase implements IRouteStaticPaymentOptionExternalLink {

	static type = PaymentOptionType.externalLink;

	readonly type = RouteStaticPaymentOptionExternalLink.type;

	@SourceOfDTO(externalLinkJsonPropertyDtoSourceMapper)
	override json!: string[];

	@Label(null)
	@View(FieldViewType.thumbnail)
	@Required()
	override logoUrl!: string;

	@Label('URL')
	@Control(FieldControlType.url)
	@View(FieldViewType.link)
	@Required()
	url!: string;

	@View(FieldViewType.boxedItems)
	@Control(FieldControlType.chip, {
		list: FiatCurrency.list,
		placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
	})
	@Mapper(FiatCurrency)
	@Required()
	currencies!: FiatCurrency[];

	@MapFromDTO()
	provider!: string;

	override get displayName(): string {
		return `${ this.type.displayName }${ isEmpty(this.name) ? '' : `: ${ this.name }` }`;
	}

	constructor(dto?: DTO<RouteStaticPaymentOptionExternalLink>) {
		super(dto);

		this.name ??= titleCase(this.pspName);
	}

}
