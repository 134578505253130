import { difference, isEmpty, sortBy, uniq } from 'lodash-es';

import { Activity } from '@bp/shared/models/core';
import type { Country, CountryCode } from '@bp/shared/models/countries';
import {
	Countries, COUNTRIES_BY_REGION, COUNTRIES_BY_SUBREGION, countryMapper,
	REGIONS_AND_SUBREGIONS
} from '@bp/shared/models/countries';
import {
	booleanMapper, Default, DTO, Entity, MapFromDTO, Mapper, Unserializable
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { isInstanceOf, isInstanceOfAbstract } from '@bp/shared/utilities/core';

import { PaymentOptionType } from '@bp/frontend/models/business';

import type { PaymentOptions, PspPaymentOptions } from './payment-options';
import { RoutePspPaymentOptionCreditCard, paymentOptionsFactory, RoutePspPaymentOptionBase } from './payment-options';
import { PaymentRouteRule } from './payment-route-rules';

export type PaymentOptionsByTypeGroup = {
	paymentOptionType: PaymentOptionType;
	paymentOptions: PaymentOptions[];
	nonsortablePaymentOptions: PaymentOptions[];
	sortablePaymentOptions: PaymentOptions[];
	activePaymentOptionsCount: number;
	isStatic: boolean;
	routeId: string;
	hasPspBasedPaymentOptionsWithoutActiveMids: boolean;
};

export type PaymentRouteKeys = NonFunctionPropertyNames<PaymentRoute>;

export class PaymentRoute extends Entity {

	@MapFromDTO()
	override name!: string;

	@Mapper((v: (Country | CountryCode)[]) => v.map(countryMapper))
	@Default([])
	countries!: Country[];

	@Mapper(paymentOptionsFactory)
	@Default([])
	psps!: PaymentOptions[];

	@Mapper(PaymentRouteRule)
	@Default([])
	rules!: PaymentRouteRule[];

	@Mapper(booleanMapper)
	@Default(false)
	isDisabled!: boolean;

	@MapFromDTO()
	readonly createdBy!: string;

	@MapFromDTO()
	readonly modifiedBy!: string;

	get status(): Activity {
		return this.isEnabled ? Activity.active : Activity.inactive;
	}

	@Unserializable()
	readonly isWorldwide: boolean;

	@Unserializable()
	readonly singleCountry: Country | null;

	@Unserializable()
	readonly paymentOptionById: Map<string, PaymentOptions>;

	@Unserializable()
	readonly paymentOptionsByTypeGroups: PaymentOptionsByTypeGroup[];

	@Unserializable()
	readonly pspPaymentOptions: PspPaymentOptions[];

	@Unserializable()
	readonly paymentCardPspPaymentOptions: PspPaymentOptions[];

	@Unserializable()
	readonly checkoutBoxPaymentOptions: PaymentOptions[];

	@Unserializable()
	readonly uniqStaticPaymentOptionIdByTypeMap: Map<PaymentOptionType, string>;

	@Unserializable()
	readonly rulesAffectingAllRoutePsps: PaymentRouteRule[];

	@Unserializable()
	readonly rulesByPspId: Map<string, PaymentRouteRule[]>;

	constructor(dto?: DTO<PaymentRoute>) {
		super(dto);

		this.psps = this._sortDisabledPspsDown();

		this.singleCountry = this._getCountryIfSingle();

		this.isWorldwide = this.singleCountry === Countries.worldwide;

		this.name = this._tryInferNameFromCountries();

		this.paymentOptionsByTypeGroups = this._createPaymentOptionsByTypeGroups();

		this.paymentOptionById = this._createPaymentOptionByIdMap();

		this.pspPaymentOptions = this.psps.filter(isInstanceOfAbstract(RoutePspPaymentOptionBase));

		this.paymentCardPspPaymentOptions = this.psps.filter(isInstanceOf(RoutePspPaymentOptionCreditCard));

		this.checkoutBoxPaymentOptions = this.pspPaymentOptions
			.filter(psp => !(psp instanceof RoutePspPaymentOptionCreditCard));

		this.uniqStaticPaymentOptionIdByTypeMap = this._createUniqStaticPaymentOptionIdByTypeMap();

		this.rulesAffectingAllRoutePsps = this.rules.filter(v => !v.merchantPspId);

		this.rulesByPspId = this._createRulesByPspIdMap();

		this._logicallyBindIsEnabledAndIsDisabled();
	}

	private _logicallyBindIsEnabledAndIsDisabled(): void {
		if (this.isEnabled === null)
			this.isEnabled = !this.isDisabled;
		else
			this.isDisabled = !this.isEnabled;
	}

	private _tryInferNameFromCountries(): string {
		if (this.isWorldwide)
			return Countries.worldwide.displayName;

		if (isEmpty(this.countries) || this.name && !REGIONS_AND_SUBREGIONS.includes(this.name))
			return this.name;

		if (this.singleCountry)
			return this.singleCountry.name;

		return this._getRegionNameIfRouteCountriesIsSubsetOfRegionCountries(COUNTRIES_BY_SUBREGION)
			?? this._getRegionNameIfRouteCountriesIsSubsetOfRegionCountries(COUNTRIES_BY_REGION)
			?? this.name;
	}

	private _getRegionNameIfRouteCountriesIsSubsetOfRegionCountries(
		countriesByRegionOrSubregion: Map<string, Country[]>,
	): string | null {
		for (const [ regionOrSubregion, regionOrSubregionCountries ] of countriesByRegionOrSubregion) {
			if (difference(this.countries, regionOrSubregionCountries).length === 0)
				return regionOrSubregion;
		}

		return null;
	}

	private _getCountryIfSingle(): Country | null {
		return this.countries.length === 1 ? this.countries[0] : null;
	}

	private _createPaymentOptionsByTypeGroups(): PaymentOptionsByTypeGroup[] {
		return [
			...PaymentOptionType
				.assignable
				.filter(v => !PaymentOptionType.staticOptions.includes(v))
				.map(paymentOptionType => (<Partial<PaymentOptionsByTypeGroup>> {
					paymentOptionType,
					paymentOptions: this.psps.filter(it => it.type === paymentOptionType),
				})),
			<Partial<PaymentOptionsByTypeGroup>> {
				paymentOptionType: PaymentOptionType.staticOption,
				isStatic: true,
				paymentOptions: this.psps.filter(it => PaymentOptionType.staticOptions.includes(it.type)),
			},
		]
			.map(v => (<PaymentOptionsByTypeGroup> {
				...v,
				routeId: this.id,
				activePaymentOptionsCount: v.paymentOptions!.filter(option => option.isEnabled).length,
				sortablePaymentOptions: v.paymentOptions!.filter(option => option.canBeSorted),
				nonsortablePaymentOptions: v.paymentOptions!.filter(option => !option.canBeSorted),
				hasPspBasedPaymentOptionsWithoutActiveMids: v.paymentOptions!.some(
					option => option.isPspBased && !(<PspPaymentOptions>option).hasActiveMids,
				),
			}))
			.filter(v => !isEmpty(v.paymentOptions));
	}

	private _createRulesByPspIdMap(): Map<string, PaymentRouteRule[]> {
		const pspSpecificRules = this.rules
			.filter(rule => !!rule.merchantPspId);

		return new Map(uniq(pspSpecificRules.map<string>(rule => rule.merchantPspId!))
			.map(pspId => [ pspId, pspSpecificRules.filter(rule => rule.merchantPspId === pspId) ]));
	}

	private _createPaymentOptionByIdMap(): Map<string, PaymentOptions> {
		return new Map(this.psps.map(paymentOption => [ paymentOption.id!, paymentOption ]));
	}

	private _sortDisabledPspsDown(): PaymentOptions[] {
		return sortBy(this.psps, psp => !psp.isEnabled);
	}

	private _createUniqStaticPaymentOptionIdByTypeMap(): Map<PaymentOptionType, string> {
		return new Map(<[PaymentOptionType, string][]>[ PaymentOptionType.wireTransfer, PaymentOptionType.cryptoWallet ]
			.map(staticPaymentOptionType => <const>[
				staticPaymentOptionType,
				this.psps.find(psp => psp.type === staticPaymentOptionType)?.id,
			])
			.filter(([ , id ]) => !!id));
	}
}
