import type { ClassMetadata, DTO } from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities/core';

import { PaymentOptionType } from '@bp/frontend/models/business';

import type { PaymentOptions, PspPaymentOptionsIntersection } from './payment-options';
import {
	RoutePspPaymentOptionApm, RoutePspPaymentOptionCreditCard, RoutePspPaymentOptionCrypto,
	RoutePspPaymentOptionExternal, RoutePspPaymentOptionPos, RoutePspPaymentOptionWallet, RoutePspPaymentOptionWalletApplePay, RoutePspPaymentOptionWalletGooglePay
} from './psp-based';
import { RouteStaticPaymentOptionCryptoWallets, RouteStaticPaymentOptionExternalLink, RouteStaticPaymentOptionWireTransfer } from './static';

const paymentOptionsConstructors = [
	RoutePspPaymentOptionApm,
	RoutePspPaymentOptionCreditCard,
	RoutePspPaymentOptionWalletApplePay,
	RoutePspPaymentOptionWalletGooglePay,
	RoutePspPaymentOptionWallet,
	RoutePspPaymentOptionExternal,
	RoutePspPaymentOptionPos,
	RoutePspPaymentOptionCrypto,

	RouteStaticPaymentOptionWireTransfer,
	RouteStaticPaymentOptionCryptoWallets,
	RouteStaticPaymentOptionExternalLink,
];

export function paymentOptionsFactory(dtos: DTO<PaymentOptions>[]): PaymentOptions[] {
	return dtos
		.filter(dto => isPresent(dto) && dto.type)
		.map(dto => paymentOptionFactory(<string> dto.type!, dto))
		.filter(isPresent);
}

export function paymentOptionFactory<T extends PaymentOptions = PaymentOptions>(
	type: PaymentOptionType | string,
	dto?: DTO<PspPaymentOptionsIntersection>,
): T {
	const paymentOptionType = PaymentOptionType.parseStrict(type);

	const targetPaymentOptionConstructor = paymentOptionsConstructors.find(
		paymentOption => paymentOption.type === paymentOptionType,
	);

	if (targetPaymentOptionConstructor)
		return (<T> new targetPaymentOptionConstructor(<any> dto));

	throw new Error(`No appropriate constructor for ${ paymentOptionType }`);
}

export function getPaymentOptionClassMetadata(
	type: PaymentOptionType,
): ClassMetadata<PaymentOptions> {
	const targetPaymentOptionConstructor = paymentOptionsConstructors.find(
		paymentOption => paymentOption.type === type,
	);

	if (targetPaymentOptionConstructor)
		return targetPaymentOptionConstructor.getClassMetadata();

	throw new Error(`No appropriate class metadata for ${ type }`);
}
