import { encode } from 'js-base64';

import { DTO } from '@bp/shared/models/metadata';

import { PaymentOptionBase } from '../payment-option-base';

export abstract class RouteStaticPaymentOptionBase extends PaymentOptionBase {

	override get displayName(): string {
		return `${ this.type.displayName }`;
	}

	/**
	 * Container of the instance serialized data, kept as an array due to backward compatibility.
	 * The reason we have it because the back is too lazy to have a typed version of this class.
	 * Contains values in base64.
	 */
	abstract json: string[];

	readonly isJsonAsBase64 = true;

	toJSON(serializingAsNestedItem: string | ''): any {
		if (serializingAsNestedItem) {
			const dto = <DTO<RouteStaticPaymentOptionBase>>{ ...this };

			// base64 to bypass WAF
			dto.json = [ encode(
				JSON.stringify(this, (key, value) => key === 'json' ? undefined : value),
			) ];

			return dto;
		}

		return this;
	}

}
