import { DescribableEnumeration } from '@bp/shared/models/core';

export class PaymentRouteRuleType extends DescribableEnumeration {

	static block = new PaymentRouteRuleType({
		description: 'If the condition is met and the rule targets a PSP, the PSP will be excluded from payment processing. Otherwise, the payment will be aborted completely.',
		affectsPspCaption: 'Exclude from payments processing this PSP:',
		affectsAnyPspCaption: 'Abort payment',
	});

	static card = new PaymentRouteRuleType({
		displayName: 'Payment card',
		description: 'Payments will be processed by the selected PSP if the condition is met.',
		affectsPspCaption: 'To process payments, use only this PSP:',
	});

	static volume = new PaymentRouteRuleType({
		displayName: 'PSP\'s processed volume',
		description: 'If the processed volume reaches its limit, the selected PSP will be excluded from payment processing',
		affectsPspCaption: 'Exclude from payments processing this PSP:',
	});

	static transaction = new PaymentRouteRuleType({
		displayName: 'Payment amount',
		description: 'If the condition is met, the selected PSP will be responsible for processing the payments.',
		affectsPspCaption: 'To process payments, use only this PSP:',
	});

	affectsPspCaption: string;

	affectsAnyPspCaption?: string;

	constructor(config: {
		displayName?: string;
		description: string;
		affectsPspCaption: string;
		affectsAnyPspCaption?: string;
	}) {
		super(config.displayName, config.description);

		this.affectsPspCaption = config.affectsPspCaption;

		this.affectsAnyPspCaption = config.affectsAnyPspCaption;
	}

}
