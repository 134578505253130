import { isArray, isString, startCase } from 'lodash-es';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { Countries } from '@bp/shared/models/countries';
import type { DTO } from '@bp/shared/models/metadata';
import {
	booleanMapper, Control, Default, Entity, FieldControlType, FieldViewType, Label, MapFromDTO, Mapper,
	MetadataEntity, Required, Unserializable, View
} from '@bp/shared/models/metadata';
import { trackById, uuid } from '@bp/shared/utilities/core';

import { PspCredential } from '../../psp';

import { PaymentRouteRuleType } from './payment-route-rule-type';

export class PaymentRouteRulePsp extends MetadataEntity {

	@MapFromDTO()
	routePspId!: string;

	@MapFromDTO()
	merchantPspId!: string;

	@MapFromDTO()
	name!: string;

	@Mapper(PspCredential)
	merchantPspCredentials!: PspCredential[];

	constructor(dto?: DTO<PaymentRouteRulePsp>) {
		super(dto);
	}

	override toString() {
		return this.name;
	}
}

export class PaymentRouteRule extends Entity {

	@Mapper(PaymentRouteRuleType)
	@Control(FieldControlType.select)
	@Required()
	@Label('Rule Type')
	@Default(null)
	type!: PaymentRouteRuleType | null;

	@Control(FieldControlType.select)
	@Required()
	@Default(null)
	conditionType!: string | 'CardBrand' | 'Country' | null;

	@Default(null)

	/**
	 * When the property is set only for rules affecting a specific route
	 */
	routePspId!: string | null;

	@MapFromDTO()

	/**
	 * When the property is null it means all payments globally or per route are affected by this rule
	 */
	merchantPspId!: string | null;

	@Default([])
	merchantPspCredentialsIds!: string[];

	/**
	 * Computed property, all communication with back goes through merchantPspCredentialsIds
	 */
	@Mapper(PspCredential)
	@Control(FieldControlType.chip, {
		placeholder: 'Add PSP credential...',
		typeControlOptions: {
			trackByFn: trackById,
		},
	})
	@Default([])
	@Label('PSP credentials')
	merchantPspCredentials!: PspCredential[] | null;

	@Default(null)
	pspName!: string | null;

	@Default(null)
	@Mapper((value, dto: DTO<PaymentRouteRule>, self: PaymentRouteRule) => {
		if (self.conditionType === 'CardBrand') {
			return isArray(value)
				? value.map(v => PaymentCardBrand.parse(v) ?? v)
				: PaymentCardBrand.parse(value) ?? value;
		}

		if (self.conditionType === 'Country') {
			return isArray(value)
				? value.map(v => isString(v) ? Countries.findByCodeString(v) : v)
				: (isString(value) ? Countries.findByCodeString(value) : value);
		}

		return value;
	})
	value!: any[] | any;

	@Mapper(booleanMapper)
	@Default(false)
	@Label('Status')
	@View(FieldViewType.booleanCircle)
	@Control(FieldControlType.switch)
	override isEnabled!: boolean;

	/**
	 * The property is used as a proxy for setting pspId and pspName using one object, so the UI control can easily
	 * set pspId and pspName with one object
	 */
	@Unserializable()
	readonly psp: PaymentRouteRulePsp | null | undefined;

	@Unserializable()
	readonly isMultiple: boolean;

	@Unserializable()
	readonly isGlobalOnly: boolean;

	@Unserializable()
	readonly displayName: string;

	constructor(dto?: DTO<PaymentRouteRule>) {
		super(dto);

		this.id ??= uuid();

		this.isMultiple = isArray(this.value);

		// TODO use metadata to determine the scope
		this.isGlobalOnly = this.conditionType === 'Country' || this.type === PaymentRouteRuleType.volume;

		this.displayName = this.type && this.conditionType
			? `${ this.type.titleDisplayName } - ${ startCase(this.conditionType) }`
			: '';

		this._setPspRelatedProperties();

		this.psp = this._getPsp();
	}

	private _setPspRelatedProperties(): void {
		if (this.psp === null) {
			this.routePspId = this.merchantPspId = this.pspName = null;

			return;
		}

		this.routePspId = this.psp?.routePspId ?? this.routePspId;

		this.merchantPspId = this.psp?.merchantPspId ?? this.merchantPspId;

		this.pspName = this.psp?.name ?? this.pspName;
	}

	private _getPsp(): PaymentRouteRulePsp | null | undefined {
		return this.merchantPspId && !this.psp
			? new PaymentRouteRulePsp({
				merchantPspId: this.merchantPspId,
				routePspId: this.routePspId!,
				name: this.pspName!,
				merchantPspCredentials: this.merchantPspCredentials!,
			})
			: this.psp;
	}

}
