
import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, FiatCurrency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	Control, Default, FieldControlType, FieldViewType, Label, Mapper, View, ViewEmptyValue
} from '@bp/shared/models/metadata';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { RoutePspPaymentOptionBase } from './route-psp-payment-option-base';

export class RoutePspPaymentOptionCreditCard extends RoutePspPaymentOptionBase {

	static type = PaymentOptionType.creditCard;

	readonly type = RoutePspPaymentOptionCreditCard.type;

	@Mapper(PaymentCardBrand)
	@Default([])
	@Control(FieldControlType.brandChips, {
		placeholder: 'Add brand...',
		required: true,
	})
	@ViewEmptyValue('Any')
	brands!: PaymentCardBrand[];

	@Mapper(FiatCurrency)
	@Default([])
	@View(FieldViewType.boxedItems)
	@Control(
		FieldControlType.chip,
		{
			required: true,
			placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
		},
	)
	@ViewEmptyValue('Any')
	currencies!: FiatCurrency[];

	@Default(false)
	@View(FieldViewType.boolean)
	@Label('Is 3D Secure')
	is3dSecure!: boolean;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	usedInPaywith!: boolean;

	constructor(dto?: DTO<RoutePspPaymentOptionCreditCard>) {
		super(dto);
	}

}
