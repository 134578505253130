
import { Validators } from '@bp/shared/features/validation/models';
import type { DTO } from '@bp/shared/models/metadata';
import { SourceOfDTO, Default, Validator, Mapper } from '@bp/shared/models/metadata';

import { cryptoWalletsJsonPropertyDtoSourceMapper, CryptoWallet, PaymentOptionType } from '@bp/frontend/models/business';
import type { IRouteStaticPaymentOptionCryptoWallets } from '@bp/frontend/models/business';

import { RouteStaticPaymentOptionBase } from './route-static-payment-option-base';

export class RouteStaticPaymentOptionCryptoWallets extends RouteStaticPaymentOptionBase implements IRouteStaticPaymentOptionCryptoWallets {

	static type = PaymentOptionType.cryptoWallet;

	readonly type = RouteStaticPaymentOptionCryptoWallets.type;

	@SourceOfDTO(cryptoWalletsJsonPropertyDtoSourceMapper)
	override json!: string[];

	@Validator(Validators.requiredArray)
	@Mapper(CryptoWallet)
	@Default(() => [ new CryptoWallet() ])
	wallets!: CryptoWallet[];

	constructor(dto?: DTO<RouteStaticPaymentOptionCryptoWallets>) {
		super(dto);
	}
}
