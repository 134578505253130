import { CryptoCurrency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Control, FieldControlType, Mapper, MetadataEntity, Required } from '@bp/shared/models/metadata';

export type CryptoWalletLegacyDTO = {
	wallet: string;
	cryptoCode: string;
};

export class CryptoWallet extends MetadataEntity {

	static fromLegacy(dto?: CryptoWalletLegacyDTO | DTO<CryptoWallet>): DTO<CryptoWallet> | undefined {
		if (dto && 'wallet' in dto) {
			return {
				address: dto.wallet,
				cryptoCurrency: dto.cryptoCode,
			};
		}

		return dto;
	}

	@Control(FieldControlType.select, {
		list: CryptoCurrency.listWithoutDeprecated,
	})
	@Mapper(CryptoCurrency)
	@Required()
	cryptoCurrency!: CryptoCurrency;

	@Required()
	address!: string;

	constructor(dto?: CryptoWalletLegacyDTO | DTO<CryptoWallet>) {
		super(CryptoWallet.fromLegacy(dto));
	}

}
