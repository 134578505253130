
import { ADD_CRYPTO_CURRENCY_CHIP_PLACEHOLDER, ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, CryptoCurrency, FiatCurrency } from '@bp/shared/models/currencies';
import {
	DTO, Hint, Control, Default, FieldControlType, Mapper, ViewEmptyValue, FieldViewType, View
} from '@bp/shared/models/metadata';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { RoutePspPaymentOptionBase } from './route-psp-payment-option-base';

export class RoutePspPaymentOptionCrypto extends RoutePspPaymentOptionBase {

	static type = PaymentOptionType.crypto;

	readonly type = RoutePspPaymentOptionCrypto.type;

	@Mapper(FiatCurrency)
	@Default([])
	@Hint('Fiat currencies this PSP can convert crypto currencies to')
	@View(FieldViewType.boxedItems)
	@Control(
		FieldControlType.chip,
		{
			required: true,
			placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
		},
	)
	@ViewEmptyValue('Any')
	currencies!: FiatCurrency[];

	@Mapper(CryptoCurrency)
	@Default([])
	@Hint('Crypto currencies this PSP can process')
	@View(FieldViewType.boxedItems)
	@Control(
		FieldControlType.chip,
		{
			required: true,
			placeholder: ADD_CRYPTO_CURRENCY_CHIP_PLACEHOLDER,
		},
	)
	@ViewEmptyValue('Any')
	cryptoCurrencies!: CryptoCurrency[];

	constructor(dto?: DTO<RoutePspPaymentOptionCrypto>) {
		super(dto);
	}

}
