import { FiatCurrency } from '@bp/shared/models/currencies';
import { CountryCode, Countries, Country } from '@bp/shared/models/countries';
import {
	DTO, Control, FieldControlType, FieldViewType, Label, MapFromDTO, Mapper, MetadataEntity, Required, View
} from '@bp/shared/models/metadata';

export class BankAccountRequisites extends MetadataEntity {

	@Control(FieldControlType.autocomplete, {
		list: FiatCurrency.list,
	})
	@Mapper(FiatCurrency)
	@Required()
	currency?: FiatCurrency;

	@Control(FieldControlType.chip, {
		list: [
			'EU',
			'Non-EU',
			...Countries.listWithWorldwide,
		],
	})
	@View(FieldViewType.chip)
	@Mapper((dtoPropertyArray: (Country | CountryCode | 'EU' | 'Non-EU')[]) => dtoPropertyArray.map(dtoArrayValue => {
		if (dtoArrayValue instanceof Country)
			return dtoArrayValue;

		if (Countries.includesCode(dtoArrayValue))
			return Countries.get(dtoArrayValue);

		return dtoArrayValue;
	}))
	allowedCountries?: (Country | 'EU' | 'Non-EU')[];

	@Label('SWIFT/BIC code')
	swiftBicCode!: string;

	@MapFromDTO()
	bankName!: string;

	@MapFromDTO()
	bankAddress!: string;

	@MapFromDTO()
	bankBranchCode!: string;

	@Label('IBAN')
	iban!: string;

	@MapFromDTO()
	accountHolder!: string;

	@MapFromDTO()
	accountNumber!: string;

	@MapFromDTO()
	accountHoldersName!: string;

	@MapFromDTO()
	accountHoldersAddress!: string;

	@MapFromDTO()
	beneficiary?: string;

	@MapFromDTO()
	beneficiaryAddress?: string;

	@MapFromDTO()
	bankDetails?: string;

	@Label('SWIFT')
	swift?: string;

	@MapFromDTO()
	clientReference?: string;

	@MapFromDTO()
	beneficiaryAccountNumber?: string;

	@MapFromDTO()
	paymentInstructions?: string;

	@MapFromDTO()
	intermediaryBank?: string;

	@MapFromDTO()
	intermediaryBankAddress?: string;

	@MapFromDTO()
	intermediarySwift?: string;

	@MapFromDTO()
	bankToBankInformation?: string;

	@MapFromDTO()
	minimumDeposit?: string;

	constructor(dto?: DTO<BankAccountRequisites>) {
		super(dto);
	}

}
