import type { FiatCurrency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Default, Label, MapFromDTO, Mapper, Unserializable } from '@bp/shared/models/metadata';

import { MerchantPsp, PspCredential } from '../../../psp';
import { PaymentOptionBase } from '../payment-option-base';

export abstract class RoutePspPaymentOptionBase extends PaymentOptionBase {

	@MapFromDTO()
	pspId!: string;

	@Default([])
	merchantPspCredentialsIds!: string[];

	@Mapper(PspCredential)
	@Default([])
	@Label('Credentials')
	merchantPspCredentials!: PspCredential[];

	@MapFromDTO()
	merchantPsp!: MerchantPsp;

	@Unserializable()
	readonly hasActiveMids: boolean;

	abstract currencies: FiatCurrency[];

	override readonly isPspBased: boolean = true;

	constructor(dto?: DTO<RoutePspPaymentOptionBase>) {
		super(dto);

		this.hasActiveMids = this.merchantPspCredentials.some(v => v.isEnabled);
	}

}
