
import { ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, FiatCurrency } from '@bp/shared/models/currencies';
import {
	DTO, FieldViewType, View, Control, Default, FieldControlType, Mapper, ViewEmptyValue
} from '@bp/shared/models/metadata';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { RoutePspPaymentOptionBase } from './route-psp-payment-option-base';

export class RoutePspPaymentOptionApm extends RoutePspPaymentOptionBase {

	static type = PaymentOptionType.apm;

	readonly type = RoutePspPaymentOptionApm.type;

	@Mapper(FiatCurrency)
	@Default([])
	@View(FieldViewType.boxedItems)
	@Control(
		FieldControlType.chip,
		{
			required: true,
			placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
		},
	)
	@ViewEmptyValue('Any')
	currencies!: FiatCurrency[];

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	usedInPaywith!: boolean;

	constructor(dto?: DTO<RoutePspPaymentOptionApm>) {
		super(dto);
	}

}
